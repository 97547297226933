import {
  addMfeEventListener,
  dispatchMfeEvent,
  MfeEvent,
  setTranslations,
} from '@ps-refarch-ux/mfe-utils';
import { pbChatbotMFEUiUrl } from 'config/api';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withAmEventApplications } from 'containers/Colleges/ActiveMatchMicroHoc';
import ActiveMatchConnectDisconnectActions from 'routes/Colleges/Match/ActiveMatchNext/connectDisconnectActions';
import { getCurrentUser } from 'selectors/auth';
import { getHighSchool } from 'selectors/highschool';
import type { State as HighSchool } from 'modules/highschool';
import { usePowerBuddyListeners } from './usePowerBuddyListeners';
import {
  parseUserInformationForOneDs,
  getOneDsInitializationData,
} from 'components/PowerBuddy/helpers';
import { translations } from 'constants/pbChatBotMfe';
import {
  collegeProfilesPermissions,
  collegeFavoritingPermissions,
} from 'selectors/permissions';
import {
  HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED,
  HOST_EVENT_POWERBUDDY_OPEN_REQUESTED,
  MFE_EVENT_CAREER_PATHWAY_GOALS,
  MFE_EVENT_POWERBUDDY_INITIALIZED,
  MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
  MFE_HOST_NAME,
  NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
} from 'constants/pbChatBotMfeEventConstants';
import s from './styles.scss';
import cx from 'classnames';
import { CustomPowerBuddyComponents } from './CustomPowerBuddyComponents';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getUserInfo } from 'selectors/colleges/supermatchnext';
import { getFavorites } from 'selectors/powerbuddy';
import { MfeComponent } from 'components/MfeComponent';
import { OneDsContextProps } from 'types/pbChatbotMfe';

export const PowerBuddyWrapper = ({ eventApplications }) => {
  // General handling of PowerBuddy events should be done in this hook
  usePowerBuddyListeners();

  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;
  const useExtensibleCollegeCard =
    featureFlags.releaseNavianceStudentPowerBuddyEnableExtensibilityCollegeCard;
  const powerBuddyFavoriteCollege =
    featureFlags.releaseNavianceStudentPowerBuddyFavoriteCollege;
  const powerBuddyConnectWithCollege =
    featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections;
  const isProfileEventEnabled = featureFlags.featureNavianceStudentPowerBuddyProfileEvent;
  const releaseNavianceRemoveStudentInfoFromContext =
    featureFlags.releaseNavianceRemoveStudentInfoFromContext;

  const [initializationData, setInitializationData] = useState(null);
  const initDataFetchedRef = useRef(false);
  const [userContext, setUserContext] = useState(null);
  const [profileContext, setProfileContext] = useState(null);

  const currentUser = useSelector(getCurrentUser);
  const highSchool: HighSchool = useSelector(getHighSchool);

  const dispatchSessionToken = (data) => {
    dispatchMfeEvent(
      'naviance_student',
      MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
      data.dataScienceSessionId
    );
  };

  const collegeProfiles = useSelector(collegeProfilesPermissions);

  const addOrEditProspectiveCollegesList = useSelector(collegeFavoritingPermissions);
  const superMatchUserInfo = useSelector(getUserInfo);
  const favorites = useSelector(getFavorites);

  const customPermissions: string[] = [];

  if (collegeProfiles) {
    if (!customPermissions.includes('college_profiles')) {
      customPermissions.push('college_profiles');
    }
  }
  if (addOrEditProspectiveCollegesList) {
    if (!customPermissions.includes('add_or_edit_prospective_colleges_list')) {
      customPermissions.push('add_or_edit_prospective_colleges_list');
    }
  }

  const updateContext = (updatedContext: OneDsContextProps) => {
    setUserContext(updatedContext);
    dispatchMfeEvent(MFE_HOST_NAME, HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED, {
      context: updatedContext,
      skipResetChat: true,
    });
  };

  useEffect(() => {
    return addMfeEventListener(
      NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
      async (mfeEvent: MfeEvent) => {
        if (!currentUser || !highSchool || !superMatchUserInfo) return;

        const { context: eventContext = {}, sourceMfe: eventSource = '' } = mfeEvent;
        let openRequestData = {};

        // If it's already initialized, just open the PowerBuddy MFE
        if (initDataFetchedRef.current) {
          if (
            eventSource === MFE_HOST_NAME &&
            eventContext?.message &&
            isProfileEventEnabled
          ) {
            if (userContext?.profileId !== eventContext?.profileId) {
              updateContext({
                ...userContext,
                profileType: eventContext?.profileType,
                profileName: eventContext?.profileName,
                profileId: eventContext?.profileId,
              });
            }
            openRequestData = { message: eventContext.message };
            setProfileContext(eventContext);
          }
          dispatchMfeEvent(
            MFE_HOST_NAME,
            HOST_EVENT_POWERBUDDY_OPEN_REQUESTED,
            openRequestData
          );
          return;
        }
        // Initialize translations for PowerBuddy
        setTranslations(translations);
        const initializationDataPromise = getOneDsInitializationData(
          currentUser,
          highSchool,
          customPermissions,
          superMatchUserInfo,
          favorites,
          releaseNavianceRemoveStudentInfoFromContext
        );

        initializationDataPromise
          ?.then((data: any) => {
            if (
              eventSource === MFE_HOST_NAME &&
              eventContext?.message &&
              isProfileEventEnabled
            ) {
              data.config.context = {
                ...data.config.context,
                profileType: eventContext?.profileType,
                profileName: eventContext?.profileName,
                profileId: eventContext?.profileId,
              };
              setProfileContext(eventContext);
            }
            setInitializationData(data);
            dispatchSessionToken(data);
            setUserContext(data.config?.context || {});
          })
          .catch((error) => {
            // log error
          });

        initDataFetchedRef.current = true;
      }
    );
  }, [currentUser, highSchool, superMatchUserInfo, favorites, translations, userContext]);

  // When first initialized, request the PowerBuddy MFE to open
  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_POWERBUDDY_INITIALIZED, (mfeEvent: MfeEvent) => {
      const { initializedCounter = 1 } = mfeEvent.context || {};

      if (initializedCounter === 0) {
        const eventPayload = isProfileEventEnabled
          ? { message: profileContext?.message }
          : {};
        dispatchMfeEvent(
          MFE_HOST_NAME,
          HOST_EVENT_POWERBUDDY_OPEN_REQUESTED,
          eventPayload
        );
      }
    });
  }, [profileContext]);

  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_CAREER_PATHWAY_GOALS, async (event) => {
      if (event?.context?.context) {
        const pathwayContext = { ...event.context.context };
        const careerContext = parseUserInformationForOneDs(
          superMatchUserInfo,
          currentUser,
          highSchool
        );

        const context = {
          ...careerContext,
          primaryGoal: pathwayContext.primaryGoal,
          secondaryGoal: pathwayContext.secondaryGoal,
          favorites: favorites,
        };

        updateContext(context);
      }
    });
  }, [currentUser, highSchool, superMatchUserInfo, favorites]);

  useEffect(() => {
    if (userContext && initDataFetchedRef.current) {
      updateContext({ ...userContext, favorites: favorites });
    }
  }, [favorites]);

  if (!initializationData) {
    return null;
  }

  return (
    // default in PowerBuddy is to show the "Add to list" button, therefore need to disable it if the Beta V2 feature flag is OFF
    <div
      id={s.boxSizing}
      className={cx({
        [s.disableAddToListButton]: !powerBuddyFavoriteCollege,
        [s.disableBuiltInCollegeCard]: useExtensibleCollegeCard,
      })}
    >
      <MfeComponent
        remote="ps_mfe_ph_ai_chatbot"
        url={pbChatbotMFEUiUrl}
        module="./PhAiChatBot"
        initializationData={initializationData}
        moduleType="window"
      />
      <CustomPowerBuddyComponents />
      {powerBuddyConnectWithCollege && (
        <ActiveMatchConnectDisconnectActions
          hostApplication={eventApplications?.POWERBUDDY}
        />
      )}
    </div>
  );
};

export default withAmEventApplications(PowerBuddyWrapper);
