export const pbNavinceProductCode = 'NAV';
export const pbShouldSkipAuthToken = false;
export const pbShouldRenderInDrawer = true;
export const bufferTimeInSeconds = 300;
export const pbFeedBackPositiveTags = ['Clear', 'Educational', 'Helpful', 'Relevant'];
export const pbFeedBackNegativeTags = [
  'Biased',
  'Confusing',
  'Inaccurate',
  'Unhelpful',
  'Slow Response Time',
];
export const pbScope = 'nsds.career-chatbot';
export const hostBufferTimeInSeconds = 420; // 7 minutes
export const translations = {
  'ai-chat-bot.app_name': 'PowerBuddy',
  'ai-chat-bot.submit_text': 'Send Message',
  'ai-chat-bot.chat_input_message_placeholder': 'Enter your question',
  'ai-chat-bot.waiting_for_response_tooltip': 'Waiting for a response.',
  'ai-chat-bot.api_error_content_message':
    'Something went wrong when I tried to answer you. Try again later.',
  'ai-chat-bot.currently_unavailable':
    "I can't respond right now. Try again in a few minutes.",
  'ai-chat-bot.invalid_configuration': 'Invalid configuration details entered.',
  'ai-chat-bot.forbidden_topic': "I'm sorry but I can't help with that.",
  'ai-chat-bot.public_initial_message_content':
    "Hi. I'm PowerBuddy, and I'm here to help. What can I do for you today?",
  'ai-chat-bot.parse_error_message':
    'Something went wrong when I tried to answer you. Try again.',
  'ai-chat-bot.api_error_content_suggestions': 'Retry',
  'ai-chat-bot.broken_image': 'The image link is broken and cannot be displayed.',
  'ai-chat-bot.broken_video': 'The video link is broken and cannot be displayed.',
  'ai-chat-bot.typing': 'Typing',
  'ai-chat-bot.powerbuddy_is_typing': 'PowerBuddy is typing',
  'ai-chat-bot.video_text_disclaimer':
    'Any video output you receive is from YouTube&#8482; and your use of the video is governed by the {{youtube_terms}} and {{google_policy}}. The video output is based on the description of the assignment from your instructor and your subsequent prompts into PowerBuddy&#8482;. \n\nPlease note that your use of PowerBuddy&#8482; for Learning is made possible through an access subscription license with your educational institution. Any processing of personal data within PowerSchool Schoology Learning is governed by those license terms and the {{powerschool_privacy_statement}}.',
  'ai-chat-bot.google_policy': "Google's Privacy Policy",
  'ai-chat-bot.youtube_terms': "YouTube's Terms of Service",
  'ai-chat-bot.powerschool_privacy_statement': 'PowerSchool Global Privacy Statement',
  'ai-chat-bot.media_credit': 'Media Credit',
  'ai-chat-bot.legal_disclosures': 'Legal Disclosures',
  'ai-chat-bot.disclaimerA': 'PowerBuddy is AI, and all AI can sometimes make mistakes.',
  'ai-chat-bot.disclaimerB': 'Your chats may be monitored and stored.',
  'ai-chat-bot.learn_more': 'Learn more',
  'ai-chat-bot.inappropriate_self_harm_1':
    "I'm really sorry to hear that you're experiencing this. I can't provide the help you need, but it is important to talk to a trusted adult or professional.",
  'ai-chat-bot.inappropriate_self_harm_2':
    "If you're in immediate danger or experiencing a crisis, contact emergency services right away.",
  'ai-chat-bot.inappropriate_hate_speech_1':
    "I'm sorry to hear that you're feeling this way. I can't provide the help you need, but it is important to talk to a trusted adult or professional.",
  'ai-chat-bot.inappropriate_hate_speech_2':
    "If you're in immediate danger or pose a threat to others, contact emergency services right away.",
  'ai-chat-bot.inappropriate_violence_1':
    "I'm sorry to hear that you're feeling this way. I can't provide the help you need, but it is important to talk to a trusted adult or professional.",
  'ai-chat-bot.inappropriate_violence_2':
    "If you're in immediate danger or pose a threat to others, contact emergency services right away.",
  'ai-chat-bot.inappropriate_illegal_acts':
    "I'm sorry, I can't help with that. If you're facing challenges, it's important to talk to someone you trust, like a family member, teacher, or school counselor. They can provide you with the support and guidance you need.",
  'ai-chat-bot.inappropriate_sexual':
    "I'm sorry, I can't help with that. If you're facing challenges, it's important to talk to someone you trust, like a family member, teacher, or school counselor. They can provide you with the support and guidance you need.",
  'ai-chat-bot.general': "I can't help with that. Try asking a different question.",
  'ai-chat-bot.add_college_to_list': 'Add to list',
  'ai-chat-bot.error_adding_college_to_list':
    'Error adding this college to your list. Please try again',
  'ai-chat-bot.visit_college_profile': 'Visit profile',
  'ai-chat-bot.visit_college_website': 'Visit website',
  'feedback-ui.positive_tag_helpful': 'Helpful',
  'feedback-ui.positive_tag_makes_sense': 'Makes sense',
  'feedback-ui.positive_tag_friendly': 'Friendly',
  'feedback-ui.positive_tag_educational': 'Educational',
  'feedback-ui.negative_tag_confusing': 'Confusing',
  'feedback-ui.negative_tag_wrong': 'Wrong',
  'feedback-ui.negative_tag_unfriendly': 'Unfriendly',
  'feedback-ui.negative_tag_unfair': 'Unfair',
  'ai-chat-bot.input_message_length':
    "Messages can't contain more than 10,000 characters",
  'ai-chat-bot.read_aloud': 'Read aloud',
  'ai-chat-bot.pause': 'Pause',
  'ai-chat-bot.powerbuddy_response': 'PowerBuddy response',
  'ai-chat-bot.powerbuddy_feedback': 'How is PowerBuddy doing?',
};
