import api, { oneDsApiUrl, pbChatBotMfeFeedbackUrl } from 'config/api';
import {
  OneDsContextProps,
  PbMfeConfigProps,
  UserContextProps,
  FeedbackMfeProps,
  Favorites,
} from '../../types/pbChatbotMfe';
import session from '../../config/session';
import ApiClient from '../../utils/ApiClient';
import localSessionStorage from 'utils/sessionStorage';
import {
  bufferTimeInSeconds,
  hostBufferTimeInSeconds,
  pbNavinceProductCode,
  pbShouldRenderInDrawer,
  pbShouldSkipAuthToken,
  pbFeedBackPositiveTags,
  pbFeedBackNegativeTags,
  pbScope,
} from 'constants/pbChatBotMfe';
import type { State as HighSchool } from 'modules/highschool';
import { dispatchMfeEvent } from '@ps-refarch-ux/mfe-utils';
import { MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED } from 'constants/pbChatBotMfeEventConstants';
import { getNameForPlanId } from 'utils/getPathwayName';
import { extendSession } from 'modules/auth';
import { Dispatch } from 'redux';
import { Wage } from 'types/wages';
import { v4 as uuidv4 } from 'uuid';
import { broadcastChannel, tabId } from 'containers/Accounts/ActivityWatcher';
import { BROADCAST_CHANNEL_EVENTS } from 'constants/activityWatcher';
import { UserInfo } from 'modules/colleges';
import type { FeatureFlags } from 'types/featureFlags';

const apiClient = new ApiClient();

// Function to fetch the Data Science session ID
export async function fetchSessionId(): Promise<string> {
  const response = await apiClient.get(`${api.users}/me/dataScience-token`);
  localSessionStorage.setItem('dsTokenExpiry', response.expires);
  return response.sessionId;
}

// Function to construct a user context object from user information
export function constructUserContext(
  userInfo: any,
  featureFlags: FeatureFlags
): UserContextProps {
  if (!userInfo) return null;

  const {
    id,
    districtId,
    currentUser,
    highSchool,
    highSchoolName,
    role,
    classYear,
    customPermissions,
  } = userInfo;

  if (featureFlags.releaseNavianceRemoveStudentInfoFromContext) {
    return {
      id,
      districtId,
      highSchoolName,
      highSchoolCeeb: highSchool?.ceebCode,
      highSchoolState: highSchool?.state,
      highSchoolCity: highSchool?.city,
      highSchoolZip: highSchool?.zip,
      studentState: currentUser?.studentAddress?.state,
      studentStreetAddress: currentUser?.studentAddress?.streetAddress,
      studentCity: currentUser?.studentAddress?.city,
      studentZip: currentUser?.studentAddress?.zip,
      email: currentUser?.email,
      phone: currentUser?.homePhone,
      role,
      gender: currentUser?.gender,
      dob: currentUser?.birthdate,
      classYear,
      ethnicCode: currentUser?.ethnicCode,
      gpa: currentUser?.gpa ?? 0,
      highestComboSat: currentUser?.highestComboSat,
      highestAct: currentUser?.highestAct,
      customPermissions: customPermissions.join(',') ?? '',
      // majors
    };
  }

  return {
    id,
    districtId,
    highSchoolName,
    highSchoolCeeb: highSchool?.ceebCode,
    highSchoolState: highSchool?.state,
    highSchoolCity: highSchool?.city,
    highSchoolZip: highSchool?.zip,
    studentState: currentUser?.studentAddress?.state,
    studentStreetAddress: currentUser?.studentAddress?.streetAddress,
    studentCity: currentUser?.studentAddress?.city,
    studentZip: currentUser?.studentAddress?.zip,
    name: currentUser?.fullName,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    email: currentUser?.email,
    phone: currentUser?.homePhone,
    role,
    gender: currentUser?.gender,
    dob: currentUser?.birthdate,
    classYear,
    ethnicCode: currentUser?.ethnicCode,
    gpa: currentUser?.gpa ?? 0,
    highestComboSat: currentUser?.highestComboSat,
    highestAct: currentUser?.highestAct,
    customPermissions: customPermissions.join(',') ?? '',
    // majors
  };
}

// Function to parse user information into a OneDsContextProps object
export function parseUserInformationForOneDs(
  userInfoDetails: UserInfo,
  currentUser: any,
  highSchool: HighSchool
): OneDsContextProps {
  const { data } = session;
  const userProfile = {
    ...data,
  };
  const { grade } = userProfile;
  const { academics = {}, highSchoolZip } = userInfoDetails;
  const gpa = academics.gpa ? academics.gpa.toString() : '0';
  const sat = academics.sat ? academics.sat.toString() : '0';
  const act = academics.act ? academics.act.toString() : '0';

  const returnType: OneDsContextProps = {
    type: 'Career',
    gradeLevel: grade.toString(),
    gpa,
    sat,
    act,
    schoolZip: highSchoolZip || highSchool.zip,
    primaryGoal: currentUser.goal ? getNameForPlanId(currentUser.goal) : undefined,
    secondaryGoal: currentUser.secondaryGoal
      ? getNameForPlanId(currentUser.secondaryGoal)
      : undefined,
  };

  return returnType;
}

export const isTokenExpiringSoon = (expiryTime, reduceTimeSeconds) => {
  // Convert the current time to seconds since the Unix epoch
  const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);

  const bufferTime = expiryTime - reduceTimeSeconds;
  return currentTimeInSeconds >= bufferTime;
};

export const checkAndRenewToken = async (dispatch?: Dispatch<any>) => {
  // Retrieve the expiration time of the data science token from session storage and convert it to a number
  const dataScienceTokenExpiry = Number(localSessionStorage.getItem('dsTokenExpiry'));
  // Retrieve the expiration time of the host token from the session data, convert it to seconds, and round down to the nearest whole number
  const hostTokenExpiry = Math.floor(session.data.expDate / 1000);
  // If the current time is within the buffer period before the expiration of either the data science token or the host token, call fetchSessionId to renew the session ID
  if (isTokenExpiringSoon(dataScienceTokenExpiry, bufferTimeInSeconds)) {
    const dsSessionId = await fetchSessionId();
    // Dispatch the Data Science session ID to the PowerBuddy MFE
    dispatchMfeEvent(
      'naviance_student',
      MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
      dsSessionId
    );
  }
  // If the current time is within the buffer period(7min) before the expiration of the host token
  if (dispatch && isTokenExpiringSoon(hostTokenExpiry, hostBufferTimeInSeconds)) {
    extendSession()(dispatch);
    broadcastChannel.postMessage({
      type: BROADCAST_CHANNEL_EVENTS.EXTEND_SESSION,
      tabId,
    });
  }
};

export async function getOneDsInitializationData(
  currentUser: any,
  highSchool: HighSchool,
  customPermissions: string[],
  superMatchUserInfo: UserInfo,
  favorites: Favorites,
  featureFlag: FeatureFlags
): Promise<{ config: PbMfeConfigProps; dataScienceSessionId: string }> {
  // Fetch data from the backend in parallel
  const sessionId = await fetchSessionId();
  const userOneDsContext = parseUserInformationForOneDs(
    superMatchUserInfo,
    currentUser,
    highSchool
  );
  const { data } = session;
  const userProfile = {
    ...data,
    currentUser,
    highSchool,
    customPermissions,
  };
  // Fetch the user's context information and student favorited data
  const userInfoContext = await constructUserContext(userProfile, featureFlag);
  // Construct the student session ID
  const studentUUid = `${uuidv4()}`;
  const feedbackMfeConfig = getFeedbackMfeConfig();
  const userOneDsContextData = { ...userOneDsContext, favorites: favorites };
  // Construct the configuration context for the PowerBuddy MFE
  const configContext: PbMfeConfigProps = {
    mfeApiPrefix: oneDsApiUrl,
    productCode: pbNavinceProductCode,
    shouldSkipAuthToken: pbShouldSkipAuthToken,
    shouldRenderInDrawer: pbShouldRenderInDrawer,
    sessionId: studentUUid,
    user: userInfoContext,
    context: userOneDsContextData,
    useOnePowerBuddy: true,
    shouldShowFeedback: true,
    feedbackUiMfeProps: feedbackMfeConfig,
  };

  // Return the configuration context
  return { config: configContext, dataScienceSessionId: sessionId };
}

export function getFeedbackMfeConfig(): FeedbackMfeProps {
  const feedbackMfeUrl = pbChatBotMfeFeedbackUrl;

  return {
    scope: pbScope,
    mfeUrl: feedbackMfeUrl,
    positiveTags: [...pbFeedBackPositiveTags],
    negativeTags: [...pbFeedBackNegativeTags],
    popoverPosition: 'top',
  };
}

// Function to fetch the latest wages for a given occupation code
export const fetchLatestWages = async (occCode: string): Promise<Wage[]> => {
  const queryString = `occCode=${occCode}`;
  const response = await apiClient.get(`${api.career}/wages/latest?${queryString}`);
  return response;
};
